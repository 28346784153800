import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckDouble } from "react-icons/fa";
import SidebarComponents from "../SidebarComponents/SidebarComponents";
import BaseUrl from '../Server/BaseUrl';
import "./HeaderComponents.css";

// Helper functions
const getNotificationsFromLocalStorage = () => {
  const notifications = localStorage.getItem("notifications");
  return notifications ? JSON.parse(notifications) : [];
};

const setNotificationsToLocalStorage = (notifications) => {
  localStorage.setItem("notifications", JSON.stringify(notifications));
};

function HeaderComponents() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(5);
  const [notifications, setNotifications] = useState(getNotificationsFromLocalStorage());
  const [prevNotificationCount, setPrevNotificationCount] = useState(0); // Track previous count
  const [isUserInteracted, setIsUserInteracted] = useState(false); // Track user interaction
  const notificationsContainerRef = useRef(null);
  const notificationSound = useRef(new Audio("/assets/Sound/notification-22-270130.mp3"));
  const navigate = useNavigate();

  //Function to close the sidebar
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  
  // Wait for user interaction
  useEffect(() => {
    const handleUserInteraction = () => {
      setIsUserInteracted(true);
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };

    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };
  }, []);

  // Fetch notifications and update state
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const existingNotifications = getNotificationsFromLocalStorage();
        if (existingNotifications.length > 0) {
          setNotifications(existingNotifications);
          return;
        }

        const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/details`);
        if (!response.ok) {
          throw new Error("Failed to fetch notifications");
        }
        const data = await response.json();

        const allNotifications = data.flatMap((user) =>
          user.notifications.map((notification) => ({
            title: notification.message,
            time: new Date().toISOString(),
            read: notification.read || false,
            type: notification.type,
          }))
        );

        setNotifications(allNotifications);
        setNotificationsToLocalStorage(allNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  // Play sound for new notifications
  useEffect(() => {
    const currentNotificationCount = notifications.length;
    const newNotifications = currentNotificationCount - prevNotificationCount;

    if (isUserInteracted && newNotifications > 0) {
      for (let i = 0; i < newNotifications; i++) {
        notificationSound.current.play().catch((error) => {
          console.error("Audio play failed:", error);
        });
      }
    }

    setPrevNotificationCount(currentNotificationCount); // Update the previous notification count
  }, [notifications, isUserInteracted]);

  // Mark notification as read
  const markAsRead = (id) => {
    const updatedNotifications = notifications.map((noti, index) =>
      index === id ? { ...noti, read: true } : noti
    );
    setNotifications(updatedNotifications);
    setNotificationsToLocalStorage(updatedNotifications);
  };

  // Mark all notifications as read
  const markAllAsRead = () => {
    const updatedNotifications = notifications.map((noti) => ({
      ...noti,
      read: true,
    }));
    setNotifications(updatedNotifications);
    setNotificationsToLocalStorage(updatedNotifications);
  };

  // Load more notifications
  const loadMoreNotifications = () => {
    setVisibleNotifications(visibleNotifications + 5);
    if (notificationsContainerRef.current) {
      notificationsContainerRef.current.scrollTop =
        notificationsContainerRef.current.scrollHeight;
    }
  };

  // Trigger navigation based on notification type
  const handleNotificationClick = (notification) => {
    if (notification.type === 1) {
      navigate("/BookingDetails");
    } else if (notification.type === 2) {
      navigate("/PropertiesUnderReview");
    }
  };

  return (
    <div>
      <div className="header" style={{ backgroundColor: "white", border: "none" }}>
        <div className="header-left">
          <img
            className="logo1"
            style={{ height: "120px", marginLeft: "0", marginTop: "-18px" }}
            src="assets/img/CARIBBEANEAZE__2_-1-removebg-preview.png"
            alt="logo"
          />
          <a style={{ height: "200px", width: "200px" }} href="#" className="logo">
            <span className="logoclass" style={{ color: "blue" }}></span>
          </a>
        </div>
        <a
          className="mobile_btn"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-label="Toggle Sidebar"
        >
          <i className="fas fa-bars"></i>
        </a>
        <SidebarComponents sidebarOpen={sidebarOpen} closeSidebar={closeSidebar}/>

        <ul className="nav user-menu">
          <li className="nav-item dropdown noti-dropdown">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <i className="fe fe-bell"></i>
              {/* Show notification count only if it's greater than 0 */}
              {notifications.filter((noti) => !noti.read).length > 0 && (
                <span className="badge badge-pill">
                  {notifications.filter((noti) => !noti.read).length}
                </span>
              )}
            </a>
            <div className="dropdown-menu notifications" onClick={(e) => e.stopPropagation()}>
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <button
                  className="mark-all-read"
                  onClick={markAllAsRead}
                  title="Mark All as Read"
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "18px",
                    cursor: "pointer",
                    marginLeft: "13.2rem",
                  }}
                >
                  <FaCheckDouble />
                </button>
              </div>
              <div className="noti-content" ref={notificationsContainerRef}>
                {notifications
                  .slice(0, visibleNotifications)
                  .map((noti, index) => (
                    <React.Fragment key={index}>
                      <div className="notification-div">
                        <li
                          className={`notification-message ${noti.read ? "read" : "unread"}`}
                        >
                          <a
                            href="#"
                            onClick={() => {
                              markAsRead(index);
                              handleNotificationClick(noti);
                            }}
                          >
                            <div className="media">
                              <span className="avatar avatar-sm">
                                <img
                                  className="avatar-img rounded-circle"
                                  alt="User"
                                  src="assets/img/profiles/avatar-02.jpg"
                                />
                              </span>
                              <div className="media-body">
                                <p className={`noti-details ${noti.read ? "read" : "unread"}`}>
                                  <span className={`noti-title ${noti.read ? "read" : "unread"}`}>
                                    {noti.title}
                                  </span>
                                </p>
                                <p className={`noti-time ${noti.read ? "read" : "unread"}`}>
                                  <span className="notification-time">
                                    {new Date(noti.time).toLocaleString()}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              <div className="topnav-dropdown-footer">
                <a href="#" onClick={loadMoreNotifications}>
                  View all Notifications
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown has-arrow">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  className="rounded-circle"
                  src="assets/img/Group.png"
                  width="31"
                  alt="User"
                />
              </span>
            </a>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                    src="assets/img/Group.png"
                    alt="User"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  <h6>Sher</h6>
                  <p className="text-muted mb-0">Owner</p>
                </div>
              </div>
              <a className="dropdown-item" href="#">
                My Profile
              </a>
              <a className="dropdown-item" href="login.html">
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
      <SidebarComponents sidebarOpen={sidebarOpen} />
    </div>
  );
}

export default HeaderComponents;
