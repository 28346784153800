import React, { useState, useEffect } from "react";
import "./WithdrawalRequest.css";
import BaseUrl from "../Server/BaseUrl";
import { NavLink } from "react-router-dom";
 
const WithdrawalRequest = () => {
  const [payouts, setPayouts] = useState([]);
  const [paypalWithdrawals, setPaypalWithdrawals] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("paypal"); // Default method is PayPal
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  // Fetch general payout data (for Bank Transfer)
  useEffect(() => {
    fetch(`${BaseUrl.BaseUrl}/api/payouts`)
      .then((response) => response.json())
      .then((data) => {
        setPayouts(data); // Set fetched data
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((err) => {
        setError("Error fetching payouts data");
        setLoading(false);
      });
  }, []);
 
  // Fetch PayPal withdrawal data
  useEffect(() => {
    fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals`)
      .then((response) => response.json())
      .then((data) => {
        setPaypalWithdrawals(data); // Set PayPal withdrawal data
        setLoading(false); // Stop loading once both data sets are fetched
      })
      .catch((err) => {
        setError("Error fetching PayPal withdrawal data");
        setLoading(false);
      });
  }, []);
 
  const updateWithdrawalStatus = (id, status) => {
    fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }),
    })
      .then((response) => response.json())
      .then(() => {
        // Refetch the data to make sure we have the latest status from the server
        fetch(`${BaseUrl.BaseUrl}/api/paypal-withdrawals`)
          .then((response) => response.json())
          .then((data) => {
            setPaypalWithdrawals(data); // Update the state with the latest data
          });
      })
      .catch((err) => {
        setError("Error updating status");
      });
  };
 
  // Function to update the withdrawal status
  const updateBankWithdrawalStatus = (id, status) => {
    fetch(`${BaseUrl.BaseUrl}/api/payouts/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }), // Send new status to the server
    })
      .then((response) => response.json())
      .then(() => {
        // Refetch the data to make sure we have the latest status from the server
        fetch(`${BaseUrl.BaseUrl}/api/payouts`)
          .then((response) => response.json())
          .then((data) => {
            setPayouts(data); // Update the state with the latest data
          });
      })
      .catch((err) => {
        setError("Error updating status");
      });
  };
 
  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value); // Update the selected method based on dropdown
  };
 
  if (loading) {
    return <div id="withdrawal-request-loading">Loading...</div>;
  }
 
  if (error) {
    return <div id="withdrawal-request-error">{error}</div>;
  }
 
  return (
    <div id="withdrawal-request-wrapper">
      <div id="withdrawal-request-container">
        <h2 id="withdrawal-request-title">Withdrawal Request Details</h2>
 
        {/* Dropdown to select payout method */}
        <div id="withdrawal-request-dropdown-container">
          <select
            id="withdrawal-request-dropdown"
            value={selectedMethod}
            onChange={handleMethodChange}
          >
            <option value="paypal">PayPal</option>
            <option value="bankTransfer">Bank Transfer</option>
          </select>
        </div>
 
        {/* Render PayPal Withdrawal Table if selected method is 'paypal' */}
        {selectedMethod === "paypal" && (
          <div id="withdrawal-request-table-container">
            <h3 id="withdrawal-request-highlight">PayPal Withdrawals</h3>
 
            {/* Scrollable table wrapper */}
            <div className="withdrawal-request-table-wrapper">
              <table id="withdrawal-request-table">
                <thead>
                  <tr>
                    <th>PayPal Account</th>
                    <th>Transaction ID</th>
                    <th>Withdrawal Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paypalWithdrawals.map(
                    (paypal) =>
                      paypal.status === "In Progress" && (
                        <tr key={paypal._id}>
                          <td>{paypal.paypalId}</td>
                          <td>{paypal._id}</td>
                          <td>${paypal.withdrawalAmount}</td>
                          <td>
                            {/* Dropdown for updating the status */}
                            <select
                              onChange={(e) =>
                                updateWithdrawalStatus(
                                  paypal._id,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">{paypal.status}</option>
                              <option value="Paid">Paid</option>
                              <option value="Rejected">Rejected</option>
                            </select>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
 
        {/* Render Bank Transfer Withdrawal Table if selected method is 'bankTransfer' */}
        {selectedMethod === "bankTransfer" && (
          <div id="withdrawal-request-table-container">
            <h3 id="withdrawal-request-highlight">Bank Transfer Withdrawals</h3>
 
            {/* Scrollable table wrapper */}
            <div className="withdrawal-request-table-wrapper">
              <table id="withdrawal-request-table">
                <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Payout Option</th>
                  <th>Withdrawal Amount</th>
                  <th>Payout Method</th>
                  <th>Bank Details</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                  {payouts
                    .filter(
                      (payout) =>
                        payout.payoutMethod === "bankTransfer" ||
                        payout.payoutMethod === "westernUnion"
                    )
                    .map(
                      (payout) =>
                        payout.status === "In Progress" && (
                          <tr key={payout._id}>
                            <td>{payout.fullName}</td>
                          <td>{payout.email}</td>
                          <td>{payout.phone}</td>
                          <td>{payout.payoutOption}</td>
                          <td>${payout.withdrawalAmount}</td>
                          <td>{payout.payoutMethod}</td>
                          <td>
                            <div>
                              {/* Bank Transfer Details */}
                              {payout.payoutMethod === "bankTransfer" &&
                                payout.bankTransferDetails && (
                                  <div>
                                    <p>
                                      <strong>Bank Name:</strong>{" "}
                                      {payout.bankTransferDetails.bankName}
                                    </p>
                                    <p>
                                      <strong>Account Number:</strong>{" "}
                                      {payout.bankTransferDetails.accountNumber}
                                    </p>
                                    <p>
                                      <strong>SWIFT Code:</strong>{" "}
                                      {payout.bankTransferDetails.swiftCode}
                                    </p>
                                    <p>
                                      <strong>IBAN:</strong>{" "}
                                      {payout.bankTransferDetails.iban}
                                    </p>
                                    <p>
                                      <strong>Routing Number:</strong>{" "}
                                      {payout.bankTransferDetails.routingNumber}
                                    </p>
                                  </div>
                                )}
 
                              {/* Western Union Details */}
                              {payout.payoutMethod === "westernUnion" &&
                                payout.westernUnionDetails && (
                                  <div>
                                    <p>
                                      <strong>Recipient Name:</strong>{" "}
                                      {payout.westernUnionDetails.recipientName}
                                    </p>
                                    <p>
                                      <strong>Recipient Address:</strong>{" "}
                                      {
                                        payout.westernUnionDetails
                                          .recipientAddress
                                      }
                                    </p>
                                    <p>
                                      <strong>Phone:</strong>{" "}
                                      {payout.westernUnionDetails.wuphone}
                                    </p>
                                    <p>
                                      <strong>Country:</strong>{" "}
                                      {payout.westernUnionDetails.country}
                                    </p>
                                  </div>
                                )}
                            </div>
                          </td>
                            <td>
                              {/* Dropdown for updating the status */}
                              <select
                                onChange={(e) =>
                                  updateBankWithdrawalStatus(
                                    payout._id,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">{payout.status}</option>
                                <option value="Paid">Paid</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
 
export default WithdrawalRequest