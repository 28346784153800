import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
      <App />  
  </BrowserRouter>
);

// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import MemberDetails from './Components/MemberDetails/MemberDetails';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App/>,
//   },
//   {
//     path: "MemberDetails",
//     element: <MemberDetails/>,
//   },

// ]);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//   <RouterProvider router={router}/>

// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
