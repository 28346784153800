import React, { useEffect, useState } from 'react';
import "./PropertiesUnderEyes.css"
// import { useState } from "react";
import PDFViewer from "../PdfViewer/PdfViewer";
import BaseUrl from '../Server/BaseUrl';
function PropertiesUnderEyes({ propertyId, close }) {

  const [propertyDetails, setPropertyDetails] = useState(null);
  const [propertyOwnerSetails, setPropertyOwnerSetails] = useState(null)

  console.log("propertyId", propertyId)
  useEffect(() => {
    // Fetch property details using the propertyId
    const fetchPropertyDetails = async () => {
      try {
        const response = await fetch(`${BaseUrl.BaseUrl}/api/owner/property/${propertyId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        console.log('userr', data)
        setPropertyDetails(data?.property);
        setPropertyOwnerSetails(data?.ownerDetails)
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    if (propertyId) {
      fetchPropertyDetails();
    }
  }, [propertyId]);


  return (
    <div className='topmargin'> <button onClick={close} className="close-btn">X</button>
      <div className='poopupeye'>
        <div className="PropertiesUnderEyes-div">
          <div className="scroll-container">

            {/* {propertyDetails?.property_images?.map((image, index) => (
      <img key={index} src={image.src} alt={image.alt} width={image.width} height={image.height} />
    ))} */}
            <div>
              {propertyDetails?.property_images?.map((propertyImage, index) => {
                const imagePath = `${BaseUrl.BaseUrl}/Images/${propertyImage.filename}`;
                console.log(`Image ${index}: ${imagePath}`);
                return (
                  <img
                    key={index}
                    className='property-image'
                    src={imagePath}
                    alt={`Image ${index}`}
                  />
                );
              })}
            </div>
          </div>
          <div className="PropertiesUnderEyes-div-6">
            <div className="PropertiesUnderEyes-div-7">
              <div className="PropertiesUnderEyes-div-8">{propertyDetails?.property_name}</div>
              <div className="PropertiesUnderEyes-div-9">
                {/* <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a03be972e1db68664094f0667e9112b59b10983bf53671bdaa870065e21c9409?apiKey=f871d783806b49c6874a3173309d5ed0&"
                className="PropertiesUnderEyes-img-5"
              /> */}
                {/* <div className="PropertiesUnderEyes-div-10">
                <span style={{fontweight: "700;"}}>122 </span>(5.0)
              </div> */}
              </div>
              <div className="PropertiesUnderEyes-div-11">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/46211689682710fabbd91e35b454c50592a065c1767722fdd1a5395ed3aeb04e?apiKey=f871d783806b49c6874a3173309d5ed0&"
                  className="PropertiesUnderEyes-img-6"
                />
                <div className="PropertiesUnderEyes-div-12">{propertyDetails?.property_type
                }</div>
              </div>
            </div>
            <div className="PropertiesUnderEyes-div-13">
              {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/85b84df3b25e23f5bc6fc201adb2660e6a65a1008a9d7716224a726ccb13c217?apiKey=f871d783806b49c6874a3173309d5ed0&"
              className="PropertiesUnderEyes-img-7"
            /> */}
              <div className="PropertiesUnderEyes-div-14">{propertyDetails?.city} {propertyDetails?.country}</div>
            </div>
            <div className="PropertiesUnderEyes-div-15">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8dea86c6904658747fcbc1427cbe481ec898a9cd4ad73c5dd70b23be71f4e63?apiKey=f871d783806b49c6874a3173309d5ed0&"
                className="PropertiesUnderEyes-img-8"
              />
              <div className="PropertiesUnderEyes-div-16">
                {propertyDetails?.street_address}
              </div>
            </div>
            <div className="PropertiesUnderEyes-div-17">
              <div className="PropertiesUnderEyes-div-18">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/406c87e2e391373055187562882ef6ecc3552e7410230f88dc66a67273b08af3?apiKey=f871d783806b49c6874a3173309d5ed0&"
                  className="PropertiesUnderEyes-img-9"
                />
                <div className="PropertiesUnderEyes-div-19">{propertyDetails?.guest_count} People</div>
              </div>
              <div className="PropertiesUnderEyes-div-20">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec8dd1b0f45066d7a45f0a3d7de89baf0655a3e310946f9e6fdb6f47f9e9b439?apiKey=f871d783806b49c6874a3173309d5ed0&"
                  className="PropertiesUnderEyes-img-10"
                />
                <div className="PropertiesUnderEyes-div-21">{propertyDetails?.bedroom_count} bedroom</div>
              </div>
              <div className="PropertiesUnderEyes-div-22">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/17e5c028efbdfd9c35833d72f64df1c85313e440146e56a3abf9b947186eda42?apiKey=f871d783806b49c6874a3173309d5ed0&"
                  className="PropertiesUnderEyes-img-11"
                />
                <div className="PropertiesUnderEyes-div-23">{propertyDetails?.bathroom_count} Bathroom</div>
              </div>
            </div>
            <div className="PropertiesUnderEyes-div-26">Description</div>
            <div className="PropertiesUnderEyes-div-27">
              {propertyDetails?.property_description}
            </div>
            <div className="PropertiesUnderEyes-div-28">Extra Services</div>
            <div className="PropertiesUnderEyes-div-29">
              {propertyDetails?.extra_service?.map((service, index) => (
                <div key={service._id} className={`PropertiesUnderEyes-div-${index + 30}`}>
                  {service.item}
                </div>
              ))}
            </div>

            <div className="PropertiesUnderEyes-div-35">Amenities</div>
            <div className="PropertiesUnderEyes-div-36">
              {propertyDetails?.amenties?.map((amenity, index) => (
                <div key={index} className={`PropertiesUnderEyes-div-${index + 37}`}>
                  {amenity}
                </div>
              ))}
            </div>
            <br />

            <p style={{ fontSize: "25px", fontWeight: "bold" }}>Uploaded Documents</p>
            <div className="owner-details">
              {/* Owner Name */}
              {propertyOwnerSetails && (
                <div>
                  <p><strong>Owner Name:</strong> {propertyOwnerSetails.first_name} {propertyOwnerSetails.last_name}</p>
                  <p><strong>Email:</strong> {propertyOwnerSetails.email}</p>
                  <p><strong>Identity Type:</strong> {propertyOwnerSetails.identity_type}</p>
                </div>
              )}

              {/* Card Images */}
              {propertyOwnerSetails?.card_image?.length > 0 && (
                <div className="card-images">
                  {propertyOwnerSetails.card_image.map((card, index) => {
                    const cardImagePath = `${BaseUrl.BaseUrl}/${card}`;
                    return (
                      <a
                        key={index}
                        href={cardImagePath}
                        download={`Document-${index + 1}`}
                        className="download-link"
                      >
                        <img
                          className="card-image"
                          src={cardImagePath}
                          alt={`Card Image ${index}`}
                          title="Click to download"
                        />
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
            <br />
            <br /> <br />

          </div>
        </div>

      </div>
    </div>

  );
}

export default PropertiesUnderEyes;


