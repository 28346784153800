import React, { useState, useEffect } from 'react';
import "./BookingHistory.css";
import UserEditComponents from '../UserEditComponents/UserEditComponents';
import BaseUrl from '../Server/BaseUrl';

function BookingHistory() {
  const [deleteMessageVisible, setDeleteMessageVisible] = useState(false);
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [component_name, setComponent_name] = useState();
  const [userRows, setUserRows] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch all bookings data when the component loads
  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await fetch(`${BaseUrl.BaseUrl}/bookingConfirmed`);
      const data = await response.json();
      const bookingsData = data.bookings;

      // Map the bookings to match the table format
      const formattedBookings = bookingsData.map((booking, index) => ({
        id: index + 1,
        user_id: booking.user_id,
        name: booking.user_name, // Replace with actual name if available in data
        phone: booking.transaction_id, // Replace with actual phone if available in data
        email: booking.nights, // Replace with actual email if available in data
        Arrive: booking.entry_date,
        Depart: booking.exit_date,
        RoomType: booking.property_id, // Replace with actual room type if available
        Payment: booking.total_amount,
        Action: ''
      }));

      // Update the state with formatted bookings
      setUserRows(formattedBookings);
      console.log('BOOKING HISTORY======>', bookingsData)
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const handleDelete = (userId) => {
    // Implement delete logic here
    const updatedUsers = userRows.filter((user) => user.id !== userId);
    setUserRows(updatedUsers);

    // Show success message
    setDeleteMessageVisible(true);

    setTimeout(() => {
      setDeleteMessageVisible(false);
    }, 3000);
  };

  const closeUserEdit = () => {
    setShowUserEdit(false);
  };

  // Calculate the indices for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = userRows.slice(indexOfFirstItem, indexOfLastItem);
  console.log("current users data====>", currentUsers)

  // Calculate total pages
  const totalPages = Math.ceil(userRows.length / itemsPerPage);

  // Handle page change
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate page numbers for pagination
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="BookingHistorycontainer">
      <div style={{ position: 'relative' }}>
        {/* Success message */}
        {deleteMessageVisible && (
          <div
            style={{
              position: 'fixed',
              top: '25%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              color: 'red',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0px 0px 15px red',
              zIndex: 1000
            }}
          >
            Declined successfully!
          </div>
        )}

        <div>
          <div style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "30px", marginTop: "30px" }}>
            <span>Booking History</span>
          </div>

          <table className='BookingHistory-Table'>
            <thead>
              <tr className='BookingHistorytr'>
                <th className='BookingHistoryTH'>ID</th>
                <th className='BookingHistoryTH'>Name</th>
                <th className='BookingHistoryTH'>Transaction ID</th>
                <th className='BookingHistoryTH'>Nights</th>
                <th className='BookingHistoryTH'>Arrive</th>
                <th className='BookingHistoryTH'>Depart</th>
                <th className='BookingHistoryTH'>Payment ($)</th>
                <th className='BookingHistoryTH'>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length > 0 ? (
                currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td className='BookingHistoryTD'>{user.id}</td>
                    <td className='BookingHistoryTD'>{user.name}</td>
                    <td className='BookingHistoryTD'>{user.phone}</td>
                    <td className='BookingHistoryTD'>{user.email}</td>
                    <td className='BookingHistoryTD'>{user.Arrive}</td>
                    <td className='BookingHistoryTD'>{user.Depart}</td>
                    <td className='BookingHistoryTD'>{user.Payment}</td>
                    <td className='BookingHistoryTD'>
                      <div style={{ display: 'flex',gap:"10px",justifyContent:'center'  }}>
                        <button style={{ color: "red", border: "none", background: "none" }} onClick={() => handleDelete(user.id)} title='Delete'>
                          <i className="bi bi-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className='BookingHistoryTD' colSpan="9" style={{ textAlign: 'center' }}>No bookings available.</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="pagination-container">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Previous
            </button>

            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageClick(number)}
                className={`pagination-button ${currentPage === number ? 'active' : ''}`}
              >
                {number}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* User Edit Component */}
      {showUserEdit && (
        <UserEditComponents
          user={userToEdit}
          closeUserEdit={closeUserEdit}
        />
      )}
    </div>
  );

}

export default BookingHistory;