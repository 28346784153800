import React, { useState, useEffect } from "react";
import "./QuieresComponents.css";
import BaseUrl from "../Server/BaseUrl";
 
function QuieresComponents() {
  const [activeLink, setActiveLink] = useState("Active");
  const [queriesData, setQueriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userRows, setUserRows] = useState([]);
 
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = userRows.slice(indexOfFirstItem, indexOfLastItem);
 
  const totalPages = Math.ceil(userRows.length / itemsPerPage);
 
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
 
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
 
  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BaseUrl.BaseUrl}/help/support`);
        const data = await response.json();
        setQueriesData(data);
        setUserRows(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
 
    fetchData();
  }, []);
 
  const centeredContent = (
    <div id="centeredContent">
      {activeLink === "Active" && (
        <div>
          <h3>Active Queries</h3>
          {loading ? (
            <p id="loading">Loading...</p>
          ) : (
            currentUsers
              .filter((query) => query.status === "active")
              .map((query) => (
                <div key={query._id} id="queryItem">
                  <div id="queryHeader">
                    <h4>{query.name}</h4>
                    <h4>({query.service_provider})</h4>
                  </div>
                  <p>
                    <span id="highlight">Query:</span> {query.message}
                  </p>
                  <p id="queryEmail">
                    <span id="highlight">Email:</span> {query.email}
                  </p>
                </div>
              ))
          )}
        </div>
      )}
 
      {activeLink === "Close" && (
        <div>
          <h3>Closed Queries</h3>
          {loading ? (
            <p id="loading">Loading...</p>
          ) : (
            currentUsers
              .filter((query) => query.status === "close")
              .map((query) => (
                <div key={query._id} id="queryItem">
                  <div id="queryHeader">
                    <h4>{query.name}</h4>
                    <h4>({query.service_provider})</h4>
                  </div>
                  <p>
                    <span id="highlight">Query:</span> {query.message}
                  </p>
                  <p id="queryEmail">
                    <span id="highlight">Email:</span> {query.email}
                  </p>
                </div>
              ))
          )}
        </div>
      )}
 
      {activeLink === "All" && (
        <div>
          <h3>All Queries</h3>
          {loading ? (
            <p id="loading">Loading...</p>
          ) : (
            currentUsers.map((query) => (
              <div key={query._id} id="queryItem">
                <div id="queryHeader">
                  <h4>{query.name}</h4>
                  <h4>({query.service_provider})</h4>
                </div>
                <p>
                  <span id="highlight">Query:</span> {query.message}
                </p>
                <p id="queryEmail">
                  <span id="highlight">Email:</span> {query.email}
                </p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
 
  return (
    <div id="QuieresComponents">
      <h2 style={{ marginBottom: "30px" }}>Queries</h2>
      <nav id="QuieresComponentsNav">
        <a
          href="#Active"
          onClick={() => handleNavLinkClick("Active")}
          id={activeLink === "Active" ? "active" : ""}
        >
          Active
        </a>
        <a
          href="#Close"
          onClick={() => handleNavLinkClick("Close")}
          id={activeLink === "Close" ? "active" : ""}
        >
          Close
        </a>
        <a
          href="#All"
          onClick={() => handleNavLinkClick("All")}
          id={activeLink === "All" ? "active" : ""}
        >
          All
        </a>
      </nav>
 
      <div id="centeredContainer">{centeredContent}</div>
 
      <div id="pagination-container">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          id="pagination-button"
        >
          Previous
        </button>
 
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageClick(number)}
            className={`pagination-button ${
              currentPage === number ? "active" : ""
            }`}
          >
            {number}
          </button>
        ))}
 
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          id="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
}
 
export default QuieresComponents;